<template>
  <div class="scheme-instance-list">
    <div class="layout-horizontal layout-middle padding-top padding-horizontal padding-bottom-small"
         style="height: 28px;">
      <back-button @click="$router.back()"></back-button>
      <div class="font-size-medium margin-left font-weight-bold">干预方案管理</div>
    </div>
    <ys-infinite-scroll-view @loadmore="loadMore">
      <div class="padding-horizontal">
        <div class="layout-horizontal margin-bottom" v-if="!schemeInstance">
          <div class="btn-start layout-vertical layout-center layout-middle font-size-large font-weight-light"
               @click="handleStartSchemeClick()"> +
            开始干预
          </div>
        </div>
        <div v-else class="scheme-card padding padding-bottom-large padding-horizontal-large margin-bottom-large">
          <div><span class="font-weight-bold"><i class="fas fa-seedling"></i><span
              class="margin-left">当前干预方案</span></span><span> · 第 <span
              class="font-color-primary font-weight-bold">{{ schemeInstance.days }}</span> 天</span></div>
          <div class="margin-vertical-large font-size-large layout-horizontal layout-top">
            <div class="mark-started margin-right">干预中</div>
            <div>
              <div>{{ schemeInstance.scheme.title }}</div>
              <div class="font-size-medium margin-top">
                开始于：{{ startTimeStr }}
              </div>
            </div>
          </div>
          <ys-button type="secondary" lighting class="margin-top-large" @click="handleStopSchemeClick(schemeInstance)">
            <span><i class="fa fa-stop font-size-small"></i> 停止干预</span>
          </ys-button>
        </div>
        <div class="list-item layout-horizontal layout-middle padding"
             :class="{'font-color-secondary': item.state !== BusinessState.STARTED}"
             v-for="item in schemeInstanceList" :key="item.id"
             v-show="!schemeInstance || schemeInstance.id !== item.id">
          <div class="layout-inflexible scheme-instance-icon">
            <i class="fas fa-seedling"></i>
          </div>
          <div class="layout-flexible margin-left font-size-medium"
          >{{ item.scheme.title }}
          </div>
          <div class="layout-inflexible layout-horizontal layout-middle">
            <div class="font-size-small margin-right">
              <span class="font-color-primary" v-if="item.state === BusinessState.STARTED">干预中</span>
              <span class="font-color-danger" v-else>已停止</span>
            </div>
            <ys-button round type="secondary" lighting v-if="item.state === BusinessState.STARTED"
                       @click="handleStopSchemeClick(item)">
              <i class="fa fa-stop font-size-medium font-color-secondary"></i>
            </ys-button>
          </div>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <scheme-picker-dialog :visible.sync="schemePickerDialogVisible"
                          :organization-id="organizationId"
                          :default-selected-scheme-ids="defaultSelectedSchemeIds"
                          @pick="handleSchemePicked"
    ></scheme-picker-dialog>
    <delete-dialog message="您确定要停止该客户的干预方案吗？" :visible.sync="stopSchemeRemindDialogVisible" confirm-button-text="停止"
                   @confirm="handleStopSchemeConfirm"></delete-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import BusinessState from "@/assets/javascript/business-state";
import YsButton from "@/components/wedigets/YsButton";
import {TimeUtils} from "@/assets/javascript/time-utils";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import SchemePickerDialog from "@/components/dialog/SchemePickerDialog";

function doLoadSchemeInstanceList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/instance/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.schemeInstanceList, res.data.list);
    if (this.schemeInstanceList.length > 0 && this.schemeInstanceList[0].state === BusinessState.STARTED) {
      this.schemeInstance = this.schemeInstanceList[0];
    } else {
      this.schemeInstance = null;
    }
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode != this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadSchemeInstanceBySchemeId(schemeId) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/instance/started/get/by_scheme_id',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      schemeId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.schemeInstanceList, [res.data]);
    if (this.schemeInstanceList.length > 0 && this.schemeInstanceList[0].state === BusinessState.STARTED) {
      this.schemeInstance = this.schemeInstanceList[0];
    } else {
      this.schemeInstance = null;
    }
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadSchemeInstanceById(instanceId) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/instance/get',
    data: {
      id: instanceId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.schemeInstanceList, [res.data]);
    if (this.schemeInstanceList.length > 0 && this.schemeInstanceList[0].state === BusinessState.STARTED) {
      this.schemeInstance = this.schemeInstanceList[0];
    } else {
      this.schemeInstance = null;
    }
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStartScheme(scheme) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/start',
    data: {
      schemeId: scheme.id,
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    console.log('start scheme...');
    window.eventBus.$emit('start:scheme');
    doLoadSchemeInstanceBySchemeId.bind(this)(scheme.id);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopScheme(schemeInstance) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/stop',
    data: {
      id: schemeInstance.id,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    window.eventBus.$emit('stop:scheme');
    doLoadSchemeInstanceById.bind(this)(schemeInstance.id);
  }).catch(() => {
    if (loadingCode != this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode != this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}


export default {
  name: "SchemeInstanceListPanel",
  mixins: [httpapi],
  components: {SchemePickerDialog, DeleteDialog, YsButton, YsInfiniteScrollView, BackButton},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      BusinessState,

      loadingCode: 1,
      busyLoadingCode: 0,
      schemeInstance: null,
      schemeInstanceList: [],
      pageNum: 1,
      totalPages: 0,

      schemePickerDialogVisible: false,

      stoppingSchemeInstance: null,
      stopSchemeRemindDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    },
    startTimeStr() {
      return this.schemeInstance ? TimeUtils.formatNormal(this.schemeInstance.startTime) : null;
    },
    defaultSelectedSchemeIds() {
      let result = new Array();
      for (let schemeInstance of this.schemeInstanceList) {
        if (schemeInstance.state === BusinessState.STARTED) {
          result.push(schemeInstance.scheme.id);
        }
      }
      return result;
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.schemeInstanceList = [];
        this.schemeInstance = null;
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.userId && this.organizationId) {
          this.loadSchemeInstanceList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadSchemeInstanceList(pageNum = 1) {
      doLoadSchemeInstanceList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadSchemeInstanceList(this.pageNum + 1);
      }
    },
    handleStopSchemeClick(schemeInstance) {
      this.stoppingSchemeInstance = schemeInstance;
      this.stopSchemeRemindDialogVisible = true;
    },
    handleStopSchemeConfirm() {
      let punchInstance = this.stoppingSchemeInstance;
      if (punchInstance) {
        doStopScheme.bind(this)(punchInstance);
      }
    },
    handleStartSchemeClick() {
      this.schemePickerDialogVisible = true;
    },
    handleSchemePicked(schemeList) {
      doStartScheme.bind(this)(schemeList[0]);
    }
  }
}
</script>

<style scoped>

.btn-start {
  width: 180px;
  height: 128px;
  border-radius: 8px;
  border: 1px solid #dadada;
  cursor: pointer;
  background-color: #f1f1f1;
  box-shadow: 0 0 2px #bec0c0;
}

.btn-start:hover {
  box-shadow: 0 0 8px #bec0c0;
}

.list-item {
  width: auto;
}

.scheme-card {
  border-radius: 8px;
  border: 1px solid #dadada;
  background-color: white;
}

.mark-started {
  background-color: #f091a6;
  color: white;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
}

.scheme-instance-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f9a7a7;
  color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>