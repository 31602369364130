const BusinessState = {
    /**
     * 已开始
     */
    STARTED: 0,

    /**
     * 已停止
     */
    STOPPED: 1,

    /**
     * 正在等待开始
     */
    WAITING: 2,

    /**
     * 已取消
     */
    CANCELED: 3,
}

export default BusinessState;