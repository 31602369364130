<template>
  <ys-dialog :title="title" :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div class="padding">
      <el-collapse-transition>
        <div v-if="selectedSchemeList.length > 0">
          <div class="margin-horizontal margin-top">已选择：</div>
          <div class="layout-horizontal layout-wrap margin-horizontal margin-top-small">
            <div class="scheme-picked" v-for="scheme in selectedSchemeList" :key="scheme.id"
                 @click="handleSchemePickedClick(scheme)">
              <span>{{ scheme.title }}</span>
              <span class="btn-delete"> <i class="el-icon-close font-size-small"></i></span>
            </div>
          </div>
        </div>
      </el-collapse-transition>
      <ys-infinite-scroll-view @loadmore="loadMore" style="height: 50vh">
        <div class="list-item padding layout-horizontal layout-middle" v-for="scheme in schemeList" :key="scheme.id"
             @click="handleSchemeClick(scheme)">
          <ys-checker :value="scheme.__selected" :disable="scheme.__disable" class="layout-inflexible"></ys-checker>
          <div class="margin-left-large font-size-large">
            <i class="fa fa-seedling"></i>
          </div>
          <div class="layout-flexible margin-left">
            <div class="font-size-large">
              <span class="font-weight-bold" v-if="scheme.commodityState === CommodityState.ON_SHELVES">售卖中</span>
              <span class="font-weight-bold" v-else>已下架</span>
              <span> · {{ scheme.title }}</span>
            </div>
            <div class="font-color-secondary font-nowrap" style="width: 40vw; margin-top: 2px;">{{ scheme.message }}
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button type="primary" lighting class="margin-left" @click="handleConfirmClick">
        <span v-if="selectedSchemeList.length > 0">选择({{ selectedSchemeList.length }}/{{ maxSelectedCount }})</span>
        <span v-else>选择</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsChecker from "@/components/wedigets/YsChecker";
import BusinessState from "@/assets/javascript/business-state";
import YsButton from "@/components/wedigets/YsButton";
import {CommodityState} from "@/assets/javascript/commodity-utils";

function doLoadSchemeList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.schemeList, res.data.list);
    this.pageNum = pageNum;
    this.totalpages = res.data.pages;
    this.refreshSchemeSelectionState();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "SchemePickerDialog",
  mixins: [httpapi],
  components: {YsButton, YsChecker, YsInfiniteScrollView, YsDialog},
  props: {
    title: {
      type: String,
      default: '选择干预方案',
    },
    organizationId: Number,
    maxSelectedCount: {
      type: Number,
      default: 1,
    },
    defaultSelectedSchemeIds: {
      type: Array,
      default() {
        return [];
      }
    },
    visible: Boolean,
    onShelves: Boolean,
  },
  data() {
    return {
      BusinessState,

      CommodityState: CommodityState,

      loadingCode: 1,
      busyLoadingCode: 0,
      schemeList: [],
      pageNum: 1,
      totalPages: 0,

      isVisible: false,

      selectedSchemeList: [],
    }
  },
  computed: {},
  watch: {
    organizationId: {
      handler() {
        this.loadingCode++;
        this.pageNum = 1;
        this.totalPages = 0;
        this.schemeList = [];
        if (this.organizationId) {
          this.loadSchemeList();
        }
      },
      immediate: true,
    },
    visible: {
      handler() {
        this.selectedSchemeList = [];
        this.refreshSchemeSelectionState();
        this.isVisible = this.visible;
      },
      immediate: true,
    },

  },
  methods: {
    refreshSchemeSelectionState() {
      for (let scheme of this.schemeList) {
        let selected = false;
        let disable = false;
        for (let defaultSchemeId of this.defaultSelectedSchemeIds) {
          if (defaultSchemeId === scheme.id) {
            selected = true;
            disable = true;
            break;
          }
        }
        if (!disable) {
          for (let selectedScheme of this.selectedSchemeList) {
            if (selectedScheme.id === scheme.id) {
              selected = true;
              break;
            }
          }
        }
        scheme.__selected = selected;
        scheme.__disable = disable;
      }
    },
    loadSchemeList(pageNum = 1) {
      doLoadSchemeList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadSchemeList(this.pageNum + 1);
      }
    },
    handleSchemeClick(scheme) {
      let selectedIndex = this.getSchemeSelectedIndex(scheme);
      if (selectedIndex >= 0) {
        this.selectedSchemeList.splice(selectedIndex, 1);
      } else {
        if (this.onShelves && scheme.commodityState !== CommodityState.ON_SHELVES) {
          this.$message.warning('请选择售卖中的干预方案');
          return;
        }
        if (this.selectedSchemeList.length < this.maxSelectedCount) {
          this.selectedSchemeList.push(scheme);
        }
      }
      this.refreshSchemeSelectionState();
      if (this.maxSelectedCount == 1 && this.selectedSchemeList.length == 1) {
        this.handleConfirmClick();
      }
    },
    handleSchemePickedClick(scheme) {
      let selectedIndex = this.getSchemeSelectedIndex(scheme);
      if (selectedIndex >= 0) {
        this.selectedSchemeList.splice(selectedIndex, 1);
      }
      this.refreshSchemeSelectionState();
    },
    getSchemeSelectedIndex(scheme) {
      for (let n = 0; n < this.selectedSchemeList.length; n++) {
        if (this.selectedSchemeList[n].id === scheme.id) return n;
      }
      return -1;
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick() {
      if (this.selectedSchemeList.length == 0) {
        this.$message.error('至少选择一个项目');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedSchemeList);
    },
  }
}
</script>

<style scoped>

.list-item {
  width: auto;
}

.scheme-picked {
  background-color: #f091a6;
  color: white;
  padding: 4px 16px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
}

.scheme-picked .btn-delete {
  opacity: 0;
}

.scheme-picked:hover .btn-delete {
  opacity: 1;
}


</style>