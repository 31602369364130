function parseDate(time) {
    let timeType = typeof time;
    switch (timeType) {
        case "string":
            return new Date(time);
        case "number":
            return new Date(time);
        case "undefined":
            return new Date();
        default:
            return time;
    }
}

function formatNormal(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    if (isToday(date)) {
        return format('今天 HH:mm', date);
    } else if (isYesterday(date)) {
        return format('昨天 HH:mm', date);
    } else if (isCurrentYear(date)) {
        return format('MM月dd日 HH:mm', date);
    } else {
        return format('yyyy年MM月dd日 HH:mm', date);
    }
}

function formatNormalDate(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    if (isToday(date)) {
        return format('今天', date);
    } else if (isYesterday(date)) {
        return format('昨天', date);
    } else if (isTomorrow(date)) {
        return format('明天', date);
    } else if (isCurrentYear(date)) {
        return format('MM月dd日', date);
    } else {
        return format('yyyy年MM月dd日', date);
    }
}

function isToday(date) {
    if (!date) return false;
    date = parseDate(date);
    let today = new Date();
    return today.getFullYear() == date.getFullYear() && today.getMonth() == date.getMonth() && today.getDate() == date.getDate();
}

function isYesterday(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    let yesterday = new Date(new Date().getTime() - 24 * 3600 * 1000);
    return yesterday.getFullYear() == date.getFullYear() && yesterday.getMonth() == date.getMonth() && yesterday.getDate() == date.getDate();
}

function isTomorrow(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    let yesterday = new Date(new Date().getTime() + 24 * 3600 * 1000);
    return yesterday.getFullYear() == date.getFullYear() && yesterday.getMonth() == date.getMonth() && yesterday.getDate() == date.getDate();
}

function isCurrentYear(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    let today = new Date();
    return today.getFullYear() == date.getFullYear();
}

function format(fmt, date) {
    if (!date) return ''
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    let ret;
    if (typeof date == 'string') date = parseDate(date);
    const opt = {
        "y+": date.getFullYear().toString(), // 年
        "M+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时（24小时）
        "h+": (date.getHours() > 12 ? (date.getHours() - 12) : date.getHours()).toString(), // 时
        "m+": date.getMinutes().toString(), // 分
        "s+": date.getSeconds().toString(), // 秒
        "a+": date.getHours() < 12 ? 'am' : 'pm', //上午下午
        "w+": date.getDay(), //星期几
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            if (k == 'a+') {
                fmt = fmt.replace(ret[1], opt[k]);
            } else if (k == 'w+') {
                let weekStr = '';
                switch (opt[k]) {
                    case 0:
                        weekStr = '日';
                        break;
                    case 1:
                        weekStr = '一';
                        break;
                    case 2:
                        weekStr = '二';
                        break;
                    case 3:
                        weekStr = '三';
                        break;
                    case 4:
                        weekStr = '四';
                        break;
                    case 5:
                        weekStr = '五';
                        break;
                    case 6:
                        weekStr = '六';
                        break;
                }
                if (ret[1].length == 1) {
                    fmt = fmt.replace(ret[1], weekStr);
                } else if (ret[1].length == 2) {
                    fmt = fmt.replace(ret[1], '周' + weekStr);
                } else {
                    fmt = fmt.replace(ret[1], '星期' + weekStr);
                }
            } else {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
    }
    return fmt;
}

function getYearStart(date) {
    date = parseDate(date);
    return new Date(date.getFullYear(), 0, 1);
}

function getYearEnd(date) {
    date = parseDate(date);
    return new Date(date.getFullYear() + 1, 0, -1);
}

function getMonthStart(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getMonthEnd(date) {
    if (typeof date == 'string' || typeof date === 'number') date = parseDate(date);
    if (date.getMonth() == 11) return new Date(date.getFullYear(), date.getMonth(), 31);
    return new Date(date.getFullYear(), date.getMonth() + 1, -1);
}

function getWeekStart(date) {
    date = parseDate(date);
    return new Date(date.getTime() - date.getDay() * 24 * 3600 * 1000);
}

function getWeekEnd(date) {
    date = parseDate(date);
    return new Date(date.getTime() + (6 - date.getDay()) * 24 * 3600 * 1000);
}

function getDayStart(date) {
    date = parseDate(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function getDayEnd(date) {
    date = parseDate(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

function monthsBetween(date1, date2) {
    date1 = parseDate(date1);
    date2 = parseDate(date2);

    return 12 * (date2.getFullYear() - date1.getFullYear()) + date2.getMonth() - date1.getMonth();
}

function weeksBetween(date1, date2) {
    date1 = parseDate(date1);
    date2 = parseDate(date2);

    date1 = getWeekStart(date1);
    date2 = getWeekStart(date2);

    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((date2.getTime() - date1.getTime()) / (7 * 24 * 3600 * 1000));
}

function daysBetween(date1, date2) {
    date1 = parseDate(date1);
    date2 = parseDate(date2);

    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return Math.floor((date2.getTime() - date1.getTime()) / (24 * 3600 * 1000));
}

function plusMonths(date, months) {
    date = parseDate(date);
    months = date.getMonth() + months;

    let yearPlused = Math.floor(months / 12);
    let monthPlused = months - yearPlused * 12;
    return new Date(date.getFullYear() + yearPlused, monthPlused, 1);
}

function plusDays(date, days) {
    date = parseDate(date);
    return new Date(date.getTime() + days * 24 * 3600 * 1000);
}

export const TimeUtils = {
    formatNormal,
    formatNormalDate,
    format,
    isToday,
    isYesterday,
    isTomorrow,
    isCurrentYear,
    parseDate,
    getYearStart,
    getYearEnd,
    getMonthStart,
    getMonthEnd,
    getWeekStart,
    getWeekEnd,
    getDayStart,
    getDayEnd,
    monthsBetween,
    weeksBetween,
    daysBetween,
    plusMonths,
    plusDays,
}

